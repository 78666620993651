const init = () => ({
  filter: {
    competitionIds: [],
    round: undefined,
    hasPlayerOfTheMatch: undefined,
    laddertemplate: undefined,
  }
});

export default {
  namespaced: true,
  state: init(),
  mutations: {
    clear(state) {
      state = Object.assign(state, init());
    },
    setCompetitionIds(state, payload) {
      state.filter.competitionIds = payload;
    },
    setRound(state, payload) {
      state.filter.round = payload;
    },
    setPlayerOfTheMatch(state, payload) {
      state.filter.hasPlayerOfTheMatch = payload;
    },
    setLaddertemplate(state, payload) {
      state.filter.laddertemplate = payload;
    }
  },
  actions: {
  },
  getters: {
    hasPlayerOfTheMatch: state => state.filter.hasPlayerOfTheMatch,
    getLaddertemplate(state) {
      return state.filter.laddertemplate;
    }
  },
};
