<template>
  <ion-header style="display: flex; align-items: center; justify-content: space-between; padding: 0 1rem;">
    <!-- <ion-button @click="selectAllLocal" color="tertiary">Check All</ion-button>
    <ion-button @click="deselectAllLocal" color="tertiary">Uncheck All</ion-button> -->
    <ion-text><h4>Filter By Role Competition Created By </h4></ion-text>
    <ion-button @click="dismissModal">Ok</ion-button>
  </ion-header>
  <ion-item>
    <ion-text style="margin-right: 0.5rem; margin-top: 0.1rem;">Arrange By:</ion-text>
    <ion-button @click="() => selectList('all')" :color="selectedList === 'all' ? 'primary' : 'secondary'">All</ion-button>
    <ion-button @click="() => selectList('type')" :color="selectedList === 'type' ? 'primary' : 'secondary'">Type</ion-button>
    <ion-button @click="() => selectList('hierarchy')" :color="selectedList === 'hierarchy' ? 'primary' : 'secondary'">Hierarchy</ion-button>
    <!-- select all button at the end -->
    <ion-checkbox slot="end" :indeterminate="true" :checked="isAllSelected" @ionChange="toggleSelectAll"></ion-checkbox>

    <!-- <ion-button @click="selectAllLocal" slot="end"  v-if="!isAllSelected" color="tertiary">Check All</ion-button> -->
    <!-- <ion-button @click="deselectAllLocal" slot="end" v-else color="tertiary">Uncheck All</ion-button> -->
  </ion-item>
  <ion-content v-if="selectedList === 'type'">
    <div class="" v-for="(typeItems, i) in getArrangeByType" :key="i">
      <AccordionElement :accordionData="typeItems" :itemType="itemType" :isParentOpen="true" :parentCheck="true" :checkItem="checkItem" :uncheckItem="uncheckItem"/>
    </div>
  </ion-content>
  <ion-content v-if="selectedList === 'hierarchy'">
    <div class="" v-for="(typeItems, i) in getArrangeByHierarchy" :key="i">
      <AccordionElement :accordionData="typeItems" :itemType="itemType" :isParentOpen="true" :parentCheck="true" :checkItem="checkItem" :uncheckItem="uncheckItem"/>
    </div>
  </ion-content>
  <ion-content v-if="selectedList === 'all'" fullscreen>
    <ion-list>
      <ion-item v-for="filter in checkedCompetitionFilter" :key="get(filter, '_id')" button :lines="index + 1 === checkedCompetitionFilter.length ? 'none': ''">
        <ion-icon
          slot="start"
          :icon="personCircleOutline"
          size="large"
          class="custom-avatar highlight"
        ></ion-icon>

        <ion-label>
          <h2>{{ filter.entity && filter.entity.name || ''}}</h2>
          <p>{{getRoleName(filter.type)}}</p>
        </ion-label>
        <ion-checkbox slot="end"
        @update:modelValue="($event) => {$event === true ? checkItem(get(filter, '_id')) : uncheckItem(get(filter, '_id'))}"
        :modelValue="filter.checked"></ion-checkbox>

      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import _ from "lodash";
import { chevronForwardOutline, personCircleOutline } from "ionicons/icons";
import { getRoleName, roles } from '@/utils/roles';
import AccordionElement from "./accordion";

const itemType = {
  accordion: 1,
  item: 2
}

export default {
  data() {
    return {
      range: _.range,
      chevronForwardOutline,
      personCircleOutline,
      getRoleName,
      itemType,
      selectedList: 'all',
      isAllSelected:true,
      get: _.get,
    };
  },
  props: {
    close: { type: Function },
    checkedCompetitionFilter: { type: Array },
    selectAll: { type: Function },
    deselectAll: { type: Function },
    refreshCompetition: { type: Function },
    checkItem: {type: Function},
    uncheckItem: {type: Function}
  },
  components: {
    AccordionElement
  },
  computed: {
    roles() {
      return this.$store.getters['user/roles'];
    },
    getArrangeByHierarchy() {
      let accordionBody = [];
      const ccf = this.checkedCompetitionFilter;
      let remainingCCF = ccf;
      const superAdmins = _.filter(ccf, (r) => r.type === roles.superAdmin);
      const stateAdmins = _.filter(ccf, (r) => r.type === roles.stateAdmin);
      const associationAdmins = _.filter(ccf, (r) => r.type === roles.associationAdmin);
      const clubAdmins = _.filter(ccf, (r) => r.type === roles.clubAdmin);
      const teamAdmins = _.filter(ccf, (r) => r.type === roles.teamAdmin);
      const referees = _.filter(ccf, (r) => r.type === roles.referee);
      let en = [
        superAdmins,
        stateAdmins,
        associationAdmins,
        clubAdmins,
        teamAdmins
      ]
      const checkIfInOrgtree = (parentId, entity) => {
        if (entity.orgtree) {
          if (entity.orgtree.club && entity.orgtree.club._id === parentId) {
            return true;
          }
          if (entity.orgtree.association && entity.orgtree.association._id === parentId) {
            return true;
          }
          if (entity.orgtree.state && entity.orgtree.state._id === parentId) {
            return true;
          }
          if (entity.orgtree.national && entity.orgtree.national._id === parentId) {
            return true;
          }
        } else if (parentId === -1) {
          return true;
        }
        return false;
      }
      const getLower = (parentId, entities) => {
        const toReturn = [];
        if (entities.length > 1) {
          entities[0].forEach((item, i) => {
            const entitiesToShift = JSON.parse(JSON.stringify(entities));
            entitiesToShift.shift();
            const lowerEntitites = getLower(entities[0][i].entity._id, entitiesToShift);
            item.lowerEntitites = lowerEntitites;
          });
        }
        entities[0].forEach((item, i) => {
          const inOrgtree = checkIfInOrgtree(parentId, entities[0][i].entity)
          if (inOrgtree) {
            toReturn.push(entities[0][i])
            remainingCCF = _.filter(remainingCCF, (rccf) => rccf._id !== item._id);
          }
        });
        return toReturn;
      };
      const allLower = getLower(-1, en);
      const makeHierarchyAccordion = (entities) => {
        const body = [];
        entities.forEach((item, i) => {
          if (item.lowerEntitites && item.lowerEntitites.length > 0) {
            body.push(this.createAccordion(entities[i].entity.name, entities[i], makeHierarchyAccordion(entities[i].lowerEntitites)))
          } else {
            body.push(this.createItem(entities[i].entity.name, entities[i]))
          }
        });

        return body;
      };
      const bodyHierarchy = makeHierarchyAccordion(allLower);
      const remainingCCFItems = _.map(remainingCCF, rccf => this.createItem(rccf.entity.name, rccf));
      const resultBody = [];
      resultBody.push(this.createAccordion('With Hierarchy', {checked: true}, bodyHierarchy));
      resultBody.push(this.createAccordion('Without Hierarchy', {checked: true}, remainingCCFItems));
      return resultBody;
    },
    getArrangeByType() {
      let accordionBody = [];
      const typeArray = [];
      const organized = _.groupBy(this.checkedCompetitionFilter, (ccf) => ccf.type);
      _.forEach(organized, (value, key) => {
        const typeItems = _.map(value, v => this.createItem(v.entity.name, v, v.checked));
        typeArray.push(this.createAccordion(getRoleName(+key), {checked: true}, typeItems))
      });
      return typeArray;
    },
  },
  methods: {
    selectList(listName) {
      this.selectedList = listName
    },
    
    dismissModal() {
      this.close();
      this.refreshCompetition();
    },
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.deselectAllLocal();
      } else {
        this.selectAllLocal();
      }
    },
    selectAllLocal() {
      this.isAllSelected = true;
      this.selectAll();
    },
    deselectAllLocal() {
      this.isAllSelected = false;
      this.deselectAll();
    },
    getLetterEntity(entity) {
      return entity && entity.name ? entity.name[0] : '';
    },
    createItem(title, ccf) {
      return {
        details: {
          title,
          ccf
        },
        type: this.itemType.item
      }
    },
    createAccordion(title, ccf, body = []) {
      return {
        details: {
          title,
          ccf
        },
        type: this.itemType.accordion,
        body
      }
    }
  },
  watch:{
    isAllSelected(val) {
      if (val) {
        this.selectAllLocal();
      } else {
        this.deselectAllLocal();
      }
    }
  }
};
</script>

<style scoped>
.custom-avatar {
  height: 40px;
  width: 40px;
  color: rgb(136, 136, 136);
}

ion-content {
  --background: #fff;
}

ion-header { display: flex; justify-content: flex-end;}
</style>
