import jwtDecode from 'jwt-decode';
import _, {
  get, filter, find, minBy
} from 'lodash';
import { roles } from '../utils/roles';
import { httpAuth } from '../utils/axios';
import { datadogRum } from '@datadog/browser-rum';

const init = () => ({
  _id: undefined,
  authenticated: false,
  profile: {
    roles: [], //  { _id, national_id, type, entity: { _id, name } }
  },
  tokens: {}, // { access_token, refresh_token, id_token, expires_in}
  activeRole: {
    _id: undefined,
    national_id: undefined,
    type: undefined,
    entity: {
      _id: undefined,
      name: undefined
    }
  },
  referees: [],
  module: {

  },
  teams: []
});
export default {
  namespaced: true,
  state: init(),
  mutations: {
    clear(state) {
      state = Object.assign(state, init());
    },
    login(state, tokens) {
      const profile = jwtDecode(tokens.id_token);
      state.profile = profile;
      state.tokens = tokens;
      state.authenticated = true;
      console.log({
        Profile: state.profile
      });
      state.profile.roles = filter(profile.roles, (x) =>
      // x.type === roles.assembleAdmin ||
        (x && x.national_id === +process.env.VUE_APP_NATIONAL_ID
        && x.type >= roles.superAdmin
        && x.type !== 101
        ));
      // Keep current active role when auth occurs (refresh token used in router guard and axios utils)
      const foundItem = find(state.profile.roles, { _id: get(state.activeRole, '_id', undefined) });
      if (!state.activeRole._id && !foundItem) {
        state.activeRole = minBy(state.profile.roles, 'type');
      }
      datadogRum.setUser({
        id: state._id,
        name: `${state.profile.firstName} ${state.profile.lastName}`,
        email: state.profile.email,
      });
    },
    logout: (state) => _.assign(state, init()),
    verified(state) {
      state.profile.verified = true;
    },
    activeRole(state, _id) {
      state.activeRole = find(state.profile.roles, { _id });
    },
    removeRole(state, _id) {
      state.profile.roles = filter(state.profile.roles, (x) => x._id !== _id);
      state.activeRole = minBy(state.profile.roles, 'type');
    },
    updateRoles(state, payload) {
      state.profile.roles = payload;
    },
    updateModule(state, payload) {
      state.module = payload;
    },
  },
  actions: {
    async updateRole({ commit, dispatch }, roleId) {
      commit('activeRole', roleId);
      await dispatch('fetchActiveTeams');
    },
    async fetchReferee({ state, commit }) {
      return httpAuth.get('assemble/api/v1/manager/users/referees')
        .then(res => {
          // fake referee roles
          state.profile.roles = _.concat(state.profile.roles, _.map(res.data.data, r => ({
            _id: +`${process.env.VUE_APP_NATIONAL_ID}7${r.memberId}`,
            entity: {
              _id: r.memberId,
              name: `${r.firstName} ${r.lastName}`
            },
            national_id: process.env.VUE_APP_NATIONAL_ID,
            type: 7
          })));
          const activeRole = minBy(state.profile.roles, 'type') || state.profile.roles[0];
          if (state.profile.roles.length) commit('activeRole', get(activeRole, '_id'));
        })
    },
    async fetchUserData({ dispatch }) {
      await dispatch('fetchActiveTeams');
    },
    async fetchActiveTeams({ state, getters }) {
      const role = getters['activeRole'];
      if (role && [roles.clubAdmin, roles.associationAdmin].includes(role.type)) {
        httpAuth.get('assemble/api/v1/manager/teams')
          .then(res => { state.teams = res.data.data; })
      } else if (role && role.type === roles.teamAdmin) {
        state.teams = [{_id: role.entity._id, name: role.entity.name}]
      } else {
        state.teams = [];
      }
    }
  },
  getters: {
    userId(state) {
      return get(state, 'profile._id');
    },
    displayName(state) {
      return `${get(state, 'profile.firstName')} ${get(state, 'profile.lastName')}`;
    },
    abbrDisplayName(state) {
      return `${get(state, 'profile.firstName[0]')}${get(state, 'profile.lastName[0]')}`;
    },
    accessToken(state) {
      return state.tokens.access_token;
    },
    refreshToken(state) {
      return state.tokens.refresh_token;
    },
    expiry(state) {
      return state.tokens.expires_in;
    },
    isAuthenticated(state) {
      return state.authenticated;
    },
    isVerified(state) {
      return state.profile.verified;
    },
    roles(state) {
      return state.profile.roles;
    },
    activeRole(state) {
      return state.activeRole;
    },
    activeRoleId(state) {
      return get(state, 'activeRole._id');
    },
    activeRoleEntityId(state) {
      return get(state, 'activeRole.entity._id');
    },
    activeRoleEntityName(state) {
      return get(state, 'activeRole.entity.name');
    },
    activeRoleType(state) {
      return get(state, 'activeRole.type') || 999;
    },
    // payload: {module-name}
    module(state) {
      return get(state, 'module');
    },
    hasRoles(state) {
      return state.profile.roles.length;
    },
    referees(state) {
      return get(state, 'referees');
    },
    refereeId(state) {
      return get(state.activeRole, 'type') === 7 ?  get(state.activeRole, 'entity._id') : undefined;
    },
    activeTeamIds(state) {
      return _.map(get(state, 'teams'), '_id');
    }
  },
};
