
import { httpAuth } from '@/utils/axios';
import { roles } from '@/utils/roles';
import _ from 'lodash';

const init = () => ({
  positions: undefined,
  homeTeamList: undefined,
  awayTeamList: undefined,
  homeSquad: undefined,
  awaySquad: undefined,
  homeData: undefined,
  homeNPData: undefined,
  awayData: undefined,
  awayNPData: undefined,
});
  
export default {
  namespaced: true,
  state: init(),        
  mutations: {
    setTeamListData(state, payload) {
      state.positions = payload.positions;
      state.homeTeamList = payload.homeTeamList;
      state.awayTeamList = payload.awayTeamList;
      state.homeSquad = payload.homeSquad;
      state.awaySquad = payload.awaySquad;
      state.homeData = payload.homeData;
      state.homeNPData = payload.homeNPData;
      state.awayData = payload.awayData;
      state.awayNPData = payload.awayNPData;
    },
    setHomeTeamList(state, payload) {
      state.homeTeamList = payload;
    },
    setAwayTeamList(state, payload) {
      state.awayTeamList = payload;
    },
    setHomeData(state, payload) {
      state.homeData = payload;
      if (state.homeTeamList) {
        state.homeTeamList.players = payload;
      } 
    },
    setHomeSquad(state, payload) {
      state.homeSquad = payload;
    },
    setAwaySquad(state, payload) {
      state.awaySquad = payload;
    },
    setAwayData(state, payload) {
      state.awayData = payload;
      if (state.awayTeamList) {
        state.awayTeamList.players = payload;
      } 
    },
    setHomeNPData(state, payload) {
      state.homeNPData = payload;
      if (state.homeTeamList) {
        state.homeTeamList.nonplayers = payload;
      }
    },
    setAwayNPData(state, payload) {
      console.log('updating away np data with', payload)
      state.awayNPData = payload;
      if (state.awayTeamList) {
        state.awayTeamList.nonplayers = payload;
      } 
    },
    // eslint-disable-next-line no-unused-vars
    clear(state) {
      state = Object.assign(state, init());
    }, 
  },
  actions: {
    async fetchSquad({ commit }, { id }) {
      try {
        const res = await httpAuth.get(`assemble/api/v1/manager/matches/${id}/squad`);
        const {homeSquad, awaySquad} = res.data.data;
        commit('setHomeSquad', homeSquad);
        commit('setAwaySquad', awaySquad);
      } catch(e) {
        throw new Error(e.response.data);
      }
    },
  },
  getters: {
    getPositions(state) {
      return state.positions;
    },
    getHomeTeamList(state) {
      return state.homeTeamList;
    },
    getAwayTeamList(state) {
      return state.awayTeamList;
    },
    getHomeSquad(state) {
      return state.homeSquad;
    },
    getAwaySquad(state) {
      return state.awaySquad;
    },
    getHomeData(state) {
      return _.map((state.homeData), p => ({...p, playing: true} ));
    },
    getHomeNPData(state) {
      return _.map((state.homeNPData), np => ({ ...np, playing: false }));
    },
    getAwayData(state) {
      return _.map((state.awayData), p => ({...p, playing: true} ));
    },
    getAwayNPData(state) {
      return _.map((state.awayNPData), np =>  ({ ...np, playing: false }));
    },
  },
};
  