
import axios from 'axios';
import moment from 'moment';
import router from '../router';
import store from '../store';

// HTTP without authentication
export const httpNoAuth = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'national-id': process.env.VUE_APP_NATIONAL_ID
  },
});

// HTTP with authentication
export const httpAuth = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'national-id': process.env.VUE_APP_NATIONAL_ID
  },
});

// Handle Refresh Token
httpAuth.interceptors.request.use((config) => {
  const originalRequest = config;
  const now = moment().valueOf();
  const expiry = store.getters['user/expiry'];
  const userId = store.getters['user/userId'];
  const refreshToken = store.getters['user/refreshToken'];

  // Token expired or about to expire
  if (expiry - 300 <= now) {
    return httpNoAuth.post('/assemble/api/v1/account/manager/authorize', {
      refresh_token: refreshToken,
      user_id: userId,
    }).then(async (response) => {
      store.commit('user/login', response.data);
      store.dispatch('national/setup');
      await store.dispatch('user/fetchReferee');
      await store.dispatch('user/fetchUserData');

      // Update Auth Header
      const token = store.getters['user/accessToken'];
      const authheader = `${userId}:${token}`;
      const encodedString = btoa(authheader);
      originalRequest.headers.Authorization = `Bearer ${encodedString}`;
      originalRequest.headers['active-role'] = store.getters['user/activeRoleId'];
      originalRequest.headers['referee-id'] = store.getters['user/refereeId'];
      return originalRequest;
    }).catch((e) => {
      store.commit('user/logout');
      router.replace('/login');
      return e;
    });
  // eslint-disable-next-line no-else-return
  } else {
    // Update Auth Header
    const accessToken = store.getters['user/accessToken'];
    const authheader = `${userId}:${accessToken}`;
    const encodedString = btoa(authheader);
    originalRequest.headers.Authorization = `Bearer ${encodedString}`;
    originalRequest.headers['active-role'] = store.getters['user/activeRoleId'];
    originalRequest.headers['referee-id'] = store.getters['user/refereeId'];
    return originalRequest;
  }
}, (err) => Promise.reject(err));

// Handle Global Error Handler
httpAuth.interceptors.response.use((response) => response, (error) => {
  const { status } = error.response || { status: 520 };

  if (status === 401) {
    // Not Authorized, redirect to login
    store.commit('user/logout');
    // store.commit('loading', false); TODO: loading
    router.replace('/login');
  }

  if (status === 520) {
    // Unknown error handler
    throw new Error(`An unexpected error happened: ${error}`);
  }

  return Promise.reject(error);
});
