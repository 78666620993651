<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <div class="pl-05">
            <img src="@/assets/logo/assemble.png" width="30" />
          </div>
        </ion-buttons>
        <ion-buttons slot="primary">
          <ion-button @click="$router.replace('/tabs/settings')">
            <ion-icon slot="icon-only" :icon="settingsOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="primary">
          <ion-button @click="$router.replace('/tabs/account')">
            <ion-icon slot="icon-only" :icon="personCircleOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title class="">{{ bannerTitle }}</ion-title>
      </ion-toolbar>
      <!-- <ion-segment scrollable class="mt-10 custom-segment" :value="segment">
        <ion-segment-button value="matches">
          <ion-label>Matches</ion-label>
        </ion-segment-button>
        <ion-segment-button value="1">
          <ion-label>Squad 1</ion-label>
        </ion-segment-button>
        <ion-segment-button value="2">
          <ion-label>Squad 2</ion-label>
        </ion-segment-button>
        <ion-segment-button value="3">
          <ion-label>Squad 3</ion-label>
        </ion-segment-button>
      </ion-segment> -->
      <ion-list>
        <ion-item class="filter-toggle-row">
          <ion-label>Filters</ion-label>
          <ion-button @click="toggleFilterList">{{ filterListShow ? 'Close' : 'Open' }}</ion-button>
        </ion-item>
        <div :class="`filter-list ${filterListShow ? '' : 'close'}`">
          <!-- <ion-list-header>
            <ion-label> Matches </ion-label>
          </ion-list-header> -->
          <ion-item >
            <ion-label>Season</ion-label>
            <ion-select placeholder="Select Season" interface="action-sheet" v-model="filterSeason" @ionChange="handleSeasonChange">
              <ion-select-option v-for="season in seasons" :key="get(season, '_id')" :value="get(season, '_id')">{{ get(season, '_id') }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>Competition</ion-label>
            <ion-select
              :value="competitionIds"
              placeholder="Filter by Competitions"
              :multiple="true"
              :interfaceOptions="customAlertOptions"
              ok-text="Select" cancel-text="Clear" @ionCancel="() => competitionIds = null"
              @ionChange="handleFilterChange('ion select competition', $event)"
            >
              <ion-select-option
                v-for="{ _id, name } in sortedCompetition"
                :key="_id"
                :value="_id"
                >{{ name }}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>Filter Competition</ion-label>
            <ion-button @click="onFilter">Set Filter</ion-button>
          </ion-item>
          <ion-item lines="none">
            <ion-label>Round</ion-label>
            <ion-select v-model="round" ok-text="Select" cancel-text="Clear" @ionCancel="() => round = undefined" :key="key" @ionChange="handleFilterChange('ion select round')" interface="action-sheet" placeholder="Filter By Round">
              <ion-select-option v-for="i in rounds" :key="i" :value="i">{{i}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="none" :key="key">
            <ion-label>Date</ion-label>
            <div class="date-picker-div">
              <VueDatePicker class="dateRangePicker" v-model="datePickerData" range 
              :teleport="true"
              :enable-time-picker="false"
              @update:model-value="handleFilterChange('vue date picker')"
              />
            </div>
          </ion-item>
        </div>
      </ion-list>
    </ion-header>
    <ion-content>
      <div v-for="(matchesGroup,index) in filteredCompetitionsWithMatches" :key="index" class="mb-2">
        <div class="flex flex-column justify-center align-center  mt-20">
          <div class="mb-10 normal-text">
            <ion-label class="">{{dayFormatter(matchesGroup[0].dateTime)}}</ion-label>
          </div >
          <!-- <div class="light-text">
            <ion-label  >{{dayFormatter(matchDay[0].dateTime)}}</ion-label>
          </div> -->
        </div>  
        <ion-card v-for="{_id, awayTeam, matchName, homeTeam, scoringEvents, dateTime, scores, status, venue, referees,meta, competition, round, currentSetId } in matchesGroup" :key="_id" class="custom-card"  @click="goMatch(_id, homeTeam, awayTeam,meta,competition)">
          <ion-card-content v-if="!meta.isBye">
            <div class="flex justify-center normal-text">
              {{matchName}}
            </div>
            <div class="flex justify-center normal-text">
              {{get(competition, 'name')}}
            </div>
            <div class="flex justify-center normal-text">
              {{get(venue, 'name', 'N/A')}}
            </div>
            <div class="flex justify-center normal-text" v-if="get(meta, 'field.name')">
              {{get(meta, 'field.name') }}
            </div>
            <div class="flex justify-center normal-text">
              {{get(round, 'abbreviation') || get(round, 'displayName')}}
            </div>
            <div class="flex justify-center light-text">{{ moment(dateTime).tz(timezone).format('LLL') }}</div>
            <ion-grid>
              <ion-row>
                <ion-col size="4" class="flex flex-column align-center">
                  <TeamLogo :team="homeTeam"/>
                  <div class="light-text text-center mt-10">{{get(homeTeam, 'name', 'TBD')}}</div>
                  <span v-if="[13].includes(nationalId)" @click.stop="downloadMemberList(homeTeam._id)">Download Member List</span>
                </ion-col>
                <!-- <ion-col v-if="status === 'pre-game'" size="4" class="flex justify-around mt-30">
                  <div class="score-text">vs</div>
                </ion-col> -->
                <ion-col size="4" class="flex flex-column align-center mt-05" >
                  <ion-row class="mb-1">
                  <div class="score-text">{{getScore('homeTeam',scoringEvents, scores,status,meta,homeTeam, competition._id)}} - {{getScore('awayTeam',scoringEvents ,scores,status,meta,awayTeam, competition._id)}}</div>
                  </ion-row>
                  <!-- <ion-badge
                  :color="gameStatusColor(status)"
                  class="mb-05 in-progress-box text-center flex align-center p-10">
                    <div class="dot"></div>
                    <p>{{ upperCase(status).replace('FIRST', '1st').replace('SECOND', '2nd').replace('THIRD', '3rd').replace('FOURTH', '4th') }}</p>
                  </ion-badge> -->
                  <ion-badge
                   :color="gameStatusColor(status)"
                   :class="gameStatusClass(status)"
                   >
                     <div v-if="gameStatusProgress(status)" class="dot"></div>
                     <div v-if="checkForfeit('homeTeam',status,meta,homeTeam) || checkForfeit('awayTeam',status,meta,awayTeam)" class="">
                       <ion-icon v-if="checkForfeit('homeTeam',status,meta,homeTeam)" :icon="arrowBackOutline"></ion-icon>{{ upperCase(status) }}<ion-icon v-if="checkForfeit('awayTeam',status,meta,awayTeam)" :icon="arrowForwardOutline"></ion-icon>
                     </div>
                     <div v-if="currentSetId && status !== 'final'">
                      <p class="text ml-05" v-if="!timerHasRan">Set {{ currentSetId }}</p>
                    </div>
                     <div v-else class="ml-05">
                       <p>{{ upperCase(status).replace('FIRST', '1st').replace('SECOND', '2nd').replace('THIRD', '3rd').replace('FOURTH', '4th') }}</p>
                     </div>

                 </ion-badge>
                  <!-- <ion-badge
                    v-if="['first-half', 'second-half', 'first-quarter', 'second-quarter', 'third-quarter', 'fourth-quarter'].includes(status)"
                  :color="gameStatusColor(status)"
                  class="mb-05 in-progress-box text-center flex align-center p-10">
                    <div class="dot"></div>
                    <p>{{ upperCase(status).replace('FIRST', '1st').replace('SECOND', '2nd').replace('THIRD', '3rd').replace('FOURTH', '4th') }}</p>
                  </ion-badge>
                  <ion-badge
                  v-else
                  :color="gameStatusColor(status)"
                  class=" mb-05 text-center flex align-center p-10">
                    <ion-icon v-if="checkForfeit('homeTeam',status,meta,homeTeam)" :icon="arrowBackOutline"></ion-icon>{{ upperCase(status) }}<ion-icon v-if="checkForfeit('awayTeam',status,meta,awayTeam)" :icon="arrowForwardOutline"></ion-icon>
                  </ion-badge> -->
                  <ion-label class="light-text font-size-11 text-center" v-for="{memberId, firstName, lastName} in referees"
                    :key="memberId">{{firstName}} {{lastName}}</ion-label>
                </ion-col>
                <ion-col size="4" class="flex flex-column align-center">
                  <TeamLogo :team="awayTeam"/>
                  <div class="light-text text-center mt-10">{{get(awayTeam, 'name', 'TBD')}}</div>
                  <span v-if="[13].includes(nationalId)" fill="clear" @click.stop="downloadMemberList(awayTeam._id)">Download Member List</span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <ion-card-content v-else>
            <ion-text> <h4 class="round-text f-bold" > Round {{ round.number }} BYES </h4> </ion-text>
            <div class="flex flex-column align-center">
              <TeamLogo :team="get(homeTeam, '_id')? homeTeam : awayTeam"/>
              <div class="team-name light-text text-center mt-10">{{get(homeTeam, '_id')? get(homeTeam, 'name') : get(homeTeam, 'name')}}</div>
            </div>
          </ion-card-content>
        </ion-card>

    </div>
    <ion-infinite-scroll
      @ionInfinite="loadData($event)"
      threshold="100px"
      id="infinite-scroll"
      :disabled="isDisabled"
    >
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more matches...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  closeOutline,
  ellipsisVertical,
  alertCircleOutline, 
  personCircleOutline, 
  arrowForwardOutline,
  arrowBackOutline, 
  chevronBackCircleOutline, 
  settingsOutline 
} from "ionicons/icons";
import {
  IonDatetime, 
  IonDatetimeButton, 
  IonModal, 
  IonInfiniteScroll, 
  modalController,
  loadingController,
  alertController,
  IonInfiniteScrollContent, 
  InfiniteScrollCustomEvent
} from "@ionic/vue";
import { getRoleName, roles } from '@/utils/roles';
import TeamLogo from './base/team-logo.vue';
import CompetitionFilter from "./components/competition-filter";
import moment from 'moment-timezone';
import _ from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {
  components: {
    TeamLogo,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    InfiniteScrollCustomEvent,
    VueDatePicker,
  },
  data() {
    const matchesCompetitions = this.$store.getters['page/matchesCompetition'];
    const matchesRound = this.$store.getters['page/matchesRound'];
    const { filter } = this.$store.state.matches;
    const startDate = new Date();
    const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
    return {
      datePickerData: [undefined, undefined],
      isDisabled: false,
      timerHasRan: false,
      closeOutline,
      pickedDate: null,
      page: 1,
      totalMatches: 0,
      chevronBackCircleOutline,
      upperCase: _.upperCase,
      alertCircleOutline,
      get: _.get,
      ellipsisVertical,
      personCircleOutline,
      arrowForwardOutline,
      arrowBackOutline,
      settingsOutline,
      competitions: [],
      competitionsWithMatches: [],
      filterSeason: undefined,
      competitionIds: matchesCompetitions ? matchesCompetitions : filter.competitionIds,
      round: matchesRound ? matchesRound : filter.round,
      rounds: [],
      matches: [],
      filter,
      key: 0,
      checkedCompetitionFilter: [],
      loaded: false,
      availableDays: [],
      customAlertOptions: {
        header: 'Select competitions',
        translucent: true,
        cssClass: 'my-custom-alert'
      },
      customAlertOptionsSeason: {
        header: 'Select season',
        translucent: true,
      },
      competitionBeingSet: false,
      roundComplete: false,
      getMatchesCount: 1,
      getCompetitionsCount: 1,
      loadingMatchesCompIds: 1,
      loadingMatchesFilterSeason: 1,
      initialLoad: true,
      nationalId: +process.env.VUE_APP_NATIONAL_ID,
      filterListShow: true,
      processMatchesFlag: false,
      handleFilterChangeFlag: false,
    };
  },
  async mounted() {
    if(this.seasons && this.seasons.length > 0) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.filterSeason = this.seasons.find(season => season.currentSeason )._id;
    }
    await this.getCompetitions();
    await this.processMatches('mounted');
  },
  computed: {
    sortedCompetition() {
      const comp = _.cloneDeep(this.competitions);
      // return comp.sort((a, b) => a.order > b.order ? 1 : -1);
      return _.sortBy(comp, 'order');
    },
    timezone() {
      return this.$store.getters['national/timezone'];
    },
    filteredCompetitionsWithMatches() {
      return this.competitionsWithMatches;
    },
    bannerTitle() {
      return process.env.VUE_APP_BANNER_TITLE;
    },
    seasons(){
      const seasons =  this.$store.state.national.seasons;
      return seasons;
    },
    nationalAppLogo() {
      return process.env.VUE_APP_LOGO || require('@/assets/logo/assemble_positive.png');
    },
    placeholder() {
      return process.env.VUE_APP_PLACEHOLDER || require('@/assets/images/club-placeholder.png');
    },
    logo() {
      const logo = process.env.VUE_APP_LOGO && process.env.VUE_APP_LOGO !== '' ? process.env.VUE_APP_LOGO : require('@/assets/images/club-placeholder.png');
      const logoMark = process.env.VUE_APP_LOGO_MARK && process.env.VUE_APP_LOGO_MARK !== '' ? process.env.VUE_APP_LOGO_MARK : require('@/assets/images/club-placeholder.png');
      return logo || logoMark;
    },
  },
  methods: {
    assignCompetitionIds(event) {
      console.log({
        event,
        targetValue: event.target.value,
      });
      this.competitionIds = event.target.value;
    },
    async downloadMemberList(teamId) {
      if (pdfMake.vfs === undefined) pdfMake.vfs = pdfFonts.pdfMake.vfs;
      const homeDD = await this.$http.get(`/assemble/api/v1/manager/teams/${teamId}/member-list-pdf`);
      // homeDD.data.data.content.unshift('\n');
      // homeDD.data.data.content.unshift({ image: 'logo', width: [100], alignment: 'center' });
      // homeDD.data.data.images = { logo: await this.getBase64Logo() };
      return pdfMake.createPdf(homeDD.data.data).download(`${teamId}-member-list.pdf`);
    },
    getBase64Logo() {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = `${ this.logo }?r=${ Math.floor(Math.random() * 100000)}`;
      });
    },
    toggleFilterList(){
      this.filterListShow = !this.filterListShow;
    },
    getByes(round) {
      console.log('round', round);
      
    },
    async getCompetitions() {
      const loading = await loadingController.create({ message: "Loading competitions..." })
      try {
        loading.present();
        console.log(`Get Competitions [Get Competitions] ${this.getCompetitionsCount}`);
        this.getCompetitionsCount++;
        const res = await this.$http.post("assemble/api/v1/manager/competitions", {
          roles: _.filter(this.checkedCompetitionFilter, c => c.checked === true),
          season: this.filterSeason
        });
        this.competitions = res.data.data;
        if (this.competitions.length && !this.filter.competitionId) {
          // this.$store.commit('matches/setCompetitionIds', this.competitions[0]._id);
          this.$store.commit('matches/setLaddertemplate', this.competitions[0].laddertemplate);
          // this.filter.competitionId = this.competitions[0]._id;
          // this.competitionId = this.competitions[0]._id;
          this.key++;
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.dismiss();
      }
    },
    async getMatches(where){
      const loading = await loadingController.create({ message: "Loading matches..." })
      try {
        loading.present();
        console.log(`Get Matches (${where}) ${this.getMatchesCount}`);
        this.getMatchesCount++;
        let nextRound;
        if(this.roundComplete) {
          const index = Object.values(this.rounds).indexOf(this.round);
          nextRound = this.rounds[index + 1];
        }

        const resMatches = await this.$http
          .post(`assemble/api/v1/manager/matches/data-table/20/${this.page}`, {
            competitionIds: _.cloneDeep(this.competitionIds),
            round: this.round,
            nextRound: nextRound,
            date: this.datePickerData,
            season: this.filterSeason
          });
        
        return resMatches;
      } catch (error) {
        console.log(error);
      } finally {
        loading.dismiss();
      }
      
      return [];
    },
    async processMatches(where, isNotRound = true) {
      this.processMatchesFlag = true;
      console.log(`Process Matches (${where})`);
      this.page = 1;
      this.competitionsWithMatches = [];
      const resMatches = await this.getMatches(where);
      this.competitionsWithMatches = this.competitionsWithMatches.concat(resMatches.data.data.pageData);
      this.totalMatches = resMatches.data.data.total;
      if (isNotRound) {
        this.rounds = resMatches.data.data.availableRounds;
        this.availableDays = resMatches.data.data.availableDays;
      }
      this.processMatchesFlag = false;
    },
    async handleSeasonChange() {
      await this.getCompetitions();
      await this.processMatches('handleSeasonChange', false);
    },
    async handleFilterChange(where, event = null) {
      if (where === 'ion select competition' && event) {
        const newValue = event.detail.value;
        if (newValue && (Array.isArray(newValue) ? !_.isEqual(newValue.sort(), this.competitionIds.sort()) : _.isEqual(newValue.split(',').map(c => +c).sort(), this.competitionIds.sort()))) {
          this.competitionIds = Array.isArray(newValue) ? newValue : newValue.split(',').map(c => +c);
          // Call any additional methods or perform updates as necessary
        } else if (!newValue || newValue === '' || newValue.length === 0) {
          this.competitionIds = null;
        }
      }

      if (this.handleFilterChangeFlag) {
        return;
      }
      this.handleFilterChangeFlag = true;
      if (where !== 'ion select round') {
        await this.processMatches('handleFilterChange');
      } else {
        await this.processMatches('handleFilterChange', false);
      }

      if (this.initialLoad) {
        try {
          const res = await this.$http.get("assemble/api/v1/manager/competitions/filterlist");
          const userRoles = res.data.data;
          const activeRole = this.$store.getters['user/activeRole'];
          const filteredRoles = _.filter(userRoles, (ur) => {
            const ownRole = activeRole._id === ur._id;
            let isIncluded = false;
            if (_.get(ur, 'entity.orgtree', null)) {
              if (activeRole.type === roles.superAdmin) {
                isIncluded = activeRole.entity._id === _.get(ur, 'entity.orgtree.national._id', null);
              } else if (activeRole.type === roles.stateAdmin) {
                isIncluded = activeRole.entity._id === _.get(ur, 'entity.orgtree.state._id', null);
              } else if (activeRole.type === roles.associationAdmin) {
                isIncluded = activeRole.entity._id === _.get(ur, 'entity.orgtree.association._id', null);
              } else if (activeRole.type === roles.clubAdmin) {
                isIncluded = activeRole.entity._id === _.get(ur, 'entity.orgtree.club._id', null);
              }
            }
            return ownRole || isIncluded;
          });
          filteredRoles.forEach((userRole) => {
            userRole.checked = true;
            this.checkedCompetitionFilter.push(userRole);
          });
          this.initialLoad = false;
        } catch (error) {
          console.log(error);
        }
      }
      this.handleFilterChangeFlag = false;
    },
    checkItem(_id) {
      const itemToCheck = _.find(this.checkedCompetitionFilter, ccf => ccf._id === _id);
      if (itemToCheck) itemToCheck.checked = true;
    },
    uncheckItem(_id) {
      const itemToCheck = _.find(this.checkedCompetitionFilter, ccf => ccf._id === _id);
      if (itemToCheck) itemToCheck.checked = false;
    },
    checkAll() {
      this.checkedCompetitionFilter.forEach((item, i) => {
        this.checkedCompetitionFilter[i].checked = true;
      });
    },
    deselectAll() {
      this.checkedCompetitionFilter.forEach((item, i) => {
        this.checkedCompetitionFilter[i].checked = false;
      });
    },
    onFilter() {
      modalController
        .create({
          component: CompetitionFilter,
          cssClass: "custom-modal",
          componentProps: {
            close: () => this.modal.dismiss(),
            checkedCompetitionFilter: this.checkedCompetitionFilter,
            selectAll: this.checkAll,
            deselectAll: this.deselectAll,
            refreshCompetition: this.getCompetitions,
            checkItem: this.checkItem,
            uncheckItem: this.uncheckItem
          }
        })
        .then(modal => {
          modal.present();
          this.modal = modal;
        });
    },
    async loadData(event) {
      if (this.processMatchesFlag) {
        return;
      }
      const { page } = this;
      const totalPage = Math.ceil(this.totalMatches / 20);
      if (page > totalPage) {
        event.target.disabled = true;
        return;
      }
      this.page++;
      const resMatches = await this.getMatches('loadData');
      this.competitionsWithMatches = this.competitionsWithMatches.concat(resMatches.data.data.pageData);
      this.competitionsWithMatches = _.sortBy(this.competitionsWithMatches, (c) => _.get(c, '[0].dateTime'));
      event.target.complete();
      if(this.page > totalPage){
        event.target.disabled = true;
      }

    },
    checkday(date){
      return this.availableDays.includes(date);
    },
    dayFormatter(dateTime) {
      // return day in format Weekday, MMM DD YYYY
      return this.moment(dateTime).format('dddd,MMM DD YYYY');

    },
    getDatePicked(){
      if(this.pickedDate){
        const pickedDate = this.moment(this.pickedDate).format('YYYY-MM-DD');
        const today = this.moment().format('YYYY-MM-DD');
        if(pickedDate === today){
          return 'Today'
        }else{
          return this.moment(this.pickedDate).format('YYYY-MM-DD');
        }
      }
      else{
        return 'Pick a date';
      }
    },
    checkForfeit(type,status,meta,team){
      if(type === 'homeTeam'){
        if(status === 'forfeit'){
          const isForfeitingTeam = _.get(meta, 'forfeitingTeam.name') === _.get(team, 'name');
          return isForfeitingTeam ? true: false;
        }
        return false;
      }else if( type === 'awayTeam'){
        if(status === 'forfeit') {
          const isForfeitingTeam = _.get(meta, 'forfeitingTeam.name') === _.get(team, 'name');
          return isForfeitingTeam ? true: false;
        }
        return false;
      }
    },
    gameStatusColor(status) {
      switch (status) {
      case 'pre-game':
        return 'primary';
      case 'final':
        return 'success';
      case 'forfeit':
      case 'washout':
        return 'warning';
      case 'disputed':
      case 'offline':
        return 'danger';
      case 'first-half':
      case 'second-half':
      case 'first-quarter':
      case 'second-quarter':
      case 'third-quarter':
      case 'fourth-quarter':
        return 'light';
      default:
        return 'primary';
      }
    },
    gameStatusClass(status) {
      return ['first-half', 'second-half', 'first-quarter', 'second-quarter', 'third-quarter', 'fourth-quarter'].includes(status) ?
        "in-progress-box mb-05 p-10" : "mb-05 text-center flex align-center p-10";
    },
    gameStatusProgress(status) {
      return ['first-half', 'second-half', 'first-quarter', 'second-quarter', 'third-quarter', 'fourth-quarter'].includes(status)
    },
    async goMatch(id, homeTeam, awayTeam,meta,competition) {
      console.log('competition', competition);
      const homeTeamId = _.get(homeTeam, '_id', undefined);
      const awayTeamId = _.get(awayTeam, '_id', undefined);
      if ((!homeTeamId || !awayTeamId) && !meta.isBye) {
        this.$error([{message:'No Home Team or Away Team found. Please add them in the match...'}]);
        return false;
      }else if(meta.isBye){
        // creare an alert to show that this is a bye match and ask if the admin want to update the status
        const alert = await alertController
          .create({
            header: 'Alert',
            message: 'This is a bye match. Do you want to update the status to final? Only matches with final status will be included in the ladder.',
            buttons: [
              {
                text: 'Yes',
                handler: async () => {
                  const loading = await loadingController.create({ message: "Updating Match Status..." })
                  loading.present();
                  const type = 'final';
                  this.$http.put(`/assemble/api/v1/manager/matches/${id}/status`, { type })
                    .then(async (res) => {
                      loading.dismiss();
                      this.$store.commit('match/setStatus', type)
                      if(type === 'final') {
                        const alert = await alertController
                          .create({
                            header: 'Alert',
                            message: 'Do you want to Sync Ladder?',
                            buttons: [
                              {
                                text: 'Yes',
                                handler: async () => {
                                  const loading = await loadingController.create({ message: "Updating Ladder..." })
                                  loading.present();
                                  await this.$http.post(`/assemble/api/v1/manager/ladders/${competition[0]._id}/refresh`).catch((e) => {
                                    console.log(e);
                                    loading.dismiss();
                                  });
                                  loading.dismiss();
                                }
                              },
                              {
                                text: 'No',
                                cssClass: 'secondary',
                              },
                            ],
                          });
                        await alert.present();
                      }
                    })
                    .catch((e) => {
                      loading.dismiss();
                      console.log({
                        e
                      });
                      const message = _.get(e, 'response.data.message', 'You are not allowed to update this match\'s status');
                      // $error([{message: 'You are not allowed to update this match\'s status'}]);
                      this.$error([{message: message}]);
                    })
                }
              },
              {
                text: 'No',
                cssClass: 'secondary',
              },
            ],
          });
        await alert.present();

        return false;


      }
      this.$router.push(`/matches/${id}`);
    },
    getTeam(status,meta){
      return status === 'forfeit'?  _.get(meta, 'forfeitingTeam.name') : '';
    },
    getScore(type, scoringEvents, scores, status, meta, team, competitionId) {
      const hasPenaltyPeriod = scoringEvents ? scoringEvents.some(ev => ev.name.toLowerCase().includes('penalty') && ev.period === 9999) : false;
      if (type === 'homeTeam' || type === 'awayTeam') {
        if (status === 'forfeit') {
          const isForfeitingTeam = _.get(meta, 'forfeitingTeam.name') === _.get(team, 'name');
          const competition = _.find(this.competitions, { _id: competitionId });
          return isForfeitingTeam ? 0 : _.get(competition, 'laddertemplate.defaultScoreFFReceived');
        }
        if (status === 'penalty' || hasPenaltyPeriod) {
          const mainScore = _.get(scores, `${type}`, 0);
          const penScoreEvents = scoringEvents
            .filter(ev => 
              ev.name.toLowerCase().includes('penalty') &&
            !ev.name.toLowerCase().includes('miss') &&
            ev.teamId === team._id
            );
          const penScore = penScoreEvents
            .reduce((acc, cur) => acc + 1, 0);
          const penScoreValues = penScoreEvents
            .reduce((acc, cur) => acc + cur.value, 0);
          return type === 'homeTeam' ? `(${penScore}) ${mainScore - penScoreValues}` : `${mainScore - penScoreValues} (${penScore})`;
        }
        return _.get(scores, `${type}`, 0);
      }
    },
  },
};
</script>
  <style>
.my-custom-alert .alert-wrapper {
  min-width:450px !important;
}
@media only screen and (max-width: 600px) {
  .my-custom-alert .alert-wrapper {
    min-width:350px !important;
  }
}
.my-custom-alert-season .alert-wrapper {
  min-width:150px !important;
  background: transparent;
}
@media only screen and (max-width: 600px) {
  .my-custom-alert-season .alert-wrapper {
    min-width:150px !important;
    background: transparent;
  }
}
</style>
<style scoped lang="scss">
ion-item {
  --padding-start: 15px;
  --padding-end: 15px;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.light-text {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.normal-text {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}
.bold-text {
  font-size: 16px;
  font-weight: bold;
}
.score-text {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  color: rgba(8, 48, 109, 0.5);
  white-space: nowrap;
}

.header {
  --background: #1a2933 !important;
  color: white;
}

.custom-segment {
  display: flex;
  justify-content: center;
  border: none;
  background: white;
  border-radius: 0px;
}

.custom-segment ::before {
  border-left: none;
}

.custom-segment .segment-button-checked {
  color: rgb(234 68 68);
  --indicator-box-shadow: none;
  border-radius: 0;
  border-bottom: 2px solid rgb(234 68 68);
}
.custom-card {
  padding: 0px;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 6px;
  cursor: pointer;
}
.custom-card .card-content-ios {
  padding: 10px;
  p {
    // margin: 0px;
  }
}

.date-picker-div {
  max-width: 20rem;
}

.filter-list {
  height: auto;
}

.filter-list.close {
  height: 0px;
}

.filter-toggle-row {
  padding: 0;
}
</style>