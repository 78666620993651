import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex'

import national from './national';
import user from './user';
import matches from './matches';
import match from './match';
import settings from './settings';
import teamList from './team-list';
import incidents from './incidents';
import page from './page';

const vuexLocal = new VuexPersistence({
//   key: 'assemble-sports-user',
  storage: window.localStorage,
  modules: ['national', 'user', 'match', 'settings', 'page']
});

export default createStore({
  modules: {
    national,
    user,
    matches,
    match,
    teamList,
    incidents,
    page,
    settings,
  },
  plugins: [vuexLocal.plugin]
})
