<template>
  <div class="accordion">
    <div color="tertiary" @click.prevent.self="toggleBody" class="accordion-item">
      <ion-label>
        <h2>{{accordionData.details.title}}</h2>
        <p class="accordion-item-sublabel">{{accordionData.details.ccf.type && getRoleName(accordionData.details.ccf.type) || ''}}</p>
      </ion-label>

      <div class="right">
        <div class="arrow">
          <!-- <img v-if="isBodyOpen && isParentOpen" src="./Up.svg" alt=""> -->
          <svg v-if="isBodyOpen && isParentOpen" width="45" height="24" viewBox="0 0 45 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="44.6464" y1="23.3536" x2="22.6465" y2="1.35355" stroke="black"/>
            <line x1="0.646447" y1="22.6464" x2="22.6464" y2="0.646449" stroke="black"/>
          </svg>

          <svg v-else width="45" height="24" viewBox="0 0 45 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.353553" y1="0.646447" x2="22.3536" y2="22.6464" stroke="black"/>
              <line x1="44.3536" y1="1.35355" x2="22.3536" y2="23.3536" stroke="black"/>
          </svg>

          <!-- <img v-else src="./Down.svg" alt=""> -->
        </div>

        <ion-checkbox slot="end"
        @update:modelValue="($event) => {toggleCheck($event)}"
        @change="() => {console.log('Wow')}"
        :modelValue="accordionData.details.ccf.checked"></ion-checkbox>
      </div>
    </div>
    <div>
      <div class="accordion-sub" :class="isBodyOpen && isParentOpen ? 'open' : ''" v-for="(bodyData, index) in accordionData.body" :key="index">
        <ion-item v-if="bodyData.type === itemType.item">
          <ion-checkbox slot="end"
          @update:modelValue="($event) => {$event === true ? checkItem(bodyData.details.ccf._id) : uncheckItem(bodyData.details.ccf._id)}"
          :modelValue="bodyData.details.ccf.checked"></ion-checkbox>
          <ion-label>
            <h2>{{bodyData.details.title}}</h2>
            <p>{{bodyData.details.ccf.type && getRoleName(bodyData.details.ccf.type) || ''}}</p>
          </ion-label>

        </ion-item >
        <Accordion v-if="bodyData.type === itemType.accordion" :accordionData="this.accordionData.body[index]" :itemType="itemType" :isParentOpen="isBodyOpen" :parentCheck="myCheck"
          :checkItem="checkItem" :uncheckItem="uncheckItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getRoleName } from '@/utils/roles';

export default{
  name: 'Accordion',
  props: {
    accordionData: { type: Object },
    itemType: { type: Object },
    isParentOpen: { type: Boolean },
    parentCheck: {type: Boolean},
    checkItem: {type: Function},
    uncheckItem: {type: Function}
  },
  data() {
    return{
      isBodyOpen: false,
      localData: this.accordionData,
      prevCheck: this.accordionData.details.ccf.checked,
      myCheck: true
    }
  },
  beforeMount() {
    if (this.parentCheck) {
      this.makeChecked()
    } else {
      this.makeUnchecked()
    }
  },
  mounted() {
    if (this.accordionData.details.ccf.checked) {
      this.makeChecked()
    } else {
      this.makeUnchecked()
    }
  },
  methods: {
    getRoleName(role) {
      return getRoleName(role);
    },
    toggleBody() {
      this.isBodyOpen = !this.isBodyOpen;
    },
    toggleCheck(event) {
      this.myCheck = event

      if (event) {
        this.makeChecked();
      } else {
        this.makeUnchecked();
      }
    },
    makeChecked() {
      this.checkItem(this.accordionData.details.ccf._id)
      this.accordionData.body.forEach((item, i) => {
        this.checkItem(this.accordionData.body[i].details.ccf._id)
      });
    },
    makeUnchecked() {
      this.uncheckItem(this.accordionData.details.ccf._id)
      this.accordionData.body.forEach((item, i) => {
        this.uncheckItem(this.accordionData.body[i].details.ccf._id)
      });
    }
  },
  watch: {
    parentCheck(newValue, oldValue) {
      this.myCheck = newValue
      if (newValue) {
        this.makeChecked()
      } else {
        this.makeUnchecked()
      }
    },
  }
};
</script>



<style>
  .accordion {
    padding-left: 1rem;
    /* z-index: 1; */
  }
  ion-checkbox {
    z-index: 5;
    /* pointer-events: auto; */
  }
  .accordion-item {
    width: 100%;
    background-color: #eaeaea;
    display: flex;
    color: black;
    padding: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
  }

  .accordion-item-sublabel {
    color: white;
  }

  .accordion-sub {
    display: none;
    /* visibility: hidden; */
    /* height: 0rem;
    transition: height 0.5s;
    transition-timing-function: ease-in-out; */
  }

  .accordion-sub.open {
    display: block;
    /* visibility: visible; */
    /* height: 100%; */
  }

  .right {
    display: flex;
    align-items: center;
  }

  .arrow {
    margin-right: 1rem;
    height: 1.6rem;
  }

  .arrow svg {
    width: 1.5rem;
    height: 1.5rem;
  }
</style>
