<template>
  <ion-app>
      <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  
});
</script>

<style>
  .ion-page {
    /* max-width: 768px; */
    margin: auto;
  }

  @media only screen and (min-width: 768px) {
    .ion-page {
      margin-top: 1.5rem auto;
      border-radius: 1rem auto !important;
    }
  }
</style>