
import { httpAuth } from '@/utils/axios';
import { roles } from '@/utils/roles';

const init = () => ({
  selectedTeamId: undefined,
  isOnline: true,
  playerOfTheMatchList:[{
    name:undefined,
    value:undefined,
    player:{
      _id:undefined,
    },
  }],
  currentTeamData: [],
  currentTeam: {
    isHomeTeam: undefined,
    name: undefined,
    fromTeamRego: undefined,
  },
  match: {
    _id: undefined,
    competition: {
      _id: undefined,
      name: undefined,
      hasPayPerMatch: undefined,
      matchFee: undefined,
      maxPayPerMatchPerMember: undefined,

    },
    venue: {
      _id: undefined,
      name: undefined,
      venueTimezone: undefined,
    },
    round: {
      type: undefined,
      displayName: undefined,
      number: undefined
    },
    dateTime: undefined,
    status: undefined,
    homeTeam: {
      _id: undefined,
      name: undefined,
    },
    awayTeam: {
      _id: undefined,
      name: undefined,
    },
    scores: {
      homeTeam: 0,
      awayTeam: 0
    },
    meta:{
      isBye: undefined,
      isTba: undefined,
      forfeitingTeam:{
        _id: undefined,
        name: undefined,
      },
      disputedNotes: [],
    },
    setScores: [],
    scoringEvents: [],
    playerMovements: [],
    setScoring: {
      useSetScoring: false,
      setScoringEvent: undefined,
      endSetValue: undefined,
    },
    useSetScoring: false,
    setScoringEvent: undefined,
    matchName: undefined
  }
});

export default {
  namespaced: true,
  state: init(),
  mutations: {
    // eslint-disable-next-line no-unused-vars
    clear(state) {
      state = Object.assign(state, init());
    },
    setCurrentTeamData(state, payload) {
      state.currentTeamData = payload;
    },
    setCurrentTeam(state, payload) {
      state.currentTeam = payload;
    },
    setMatch(state, payload) {
      state.match = Object.assign(state.match, payload);
      if (state.match.homeTeam) state.selectedTeamId = state.match.homeTeam._id;
      else if (state.match.awayTeam) state.selectedTeamId = state.match.awayTeam._id;
    },
    setEvents(state, { type, events }) { // scoringEvents || playerMovements
      state.match[type] = events
    },
    setStatus(state, payload) {
      state.match.status = payload;
    },
    setForfeitingTeam(state, payload) {
      state.match.meta.forfeitingTeam = payload;
    },
    setScores(state, payload) {
      state.match.scores = Object.assign({}, payload);
    },
    setSetScores(state, payload) {
      state.match.setScores = Object.assign([], payload);
    },
    setCurrentSetId(state, payload) {
      state.match.currentSetId = payload;
    },
    setSelectedTeamId(state, payload) {
      state.selectedTeamId = payload;
    },
    setPlayerOfTheMatchList(state, payload) {
      state.playerOfTheMatchList = payload;
    },
    setReferees(state, payload) {
      state.match.referees = payload;
    },
    setOnlineStatus(state, payload) {
      state.isOnline = payload;
    }
  },
  actions: {
    async setup({ commit }, { id }) {
      try {
        const res = await httpAuth.get(`assemble/api/v1/manager/matches/${id}`)
        commit('setMatch', res.data.data);
      } catch(e) {
        throw new Error(e.response.data);
      }
    },
    async addEvent({ commit, state }, { type, event }) {
      try {
        const res = await httpAuth.post(`/assemble/api/v1/manager/matches/${state.match._id}/${type}`, event)
        const response = res.data.data;
        const eventType = type === 'scorings' ? 'scoringEvents' : 'playerMovements';
        const events = state.match[eventType];
        if (eventType === 'playerMovements') commit('setEvents', { type: eventType, events: [...events, event]});
        if (response && !response.isSetScoringEvent)  {
          commit('setEvents', { type: eventType, events: [...events, event]});
          commit('setScores', response.scores)
        }

        if (response.setScores) commit('setSetScores', response.setScores);
        if (response.currentSetId) commit('setCurrentSetId', response.currentSetId);
      } catch(e) {
        throw new Error(e.response.data);
      }
    }
  },
  getters: {
    isOnline(state) {
      return state.isOnline
    },
    currentTeamData(state) {
      return state.currentTeamData;
    },
    currentTeam(state) {
      return state.currentTeam;
    },
    matchDetails(state) {
      return state.match;
    },
    homeScore(state) {
      return state.match.scoringEvents.reduce((acc, current) => current.isHomeTeam
        ? acc += current.value
        : acc
      , 0)
    },
    awayScore(state) {
      return state.match.scoringEvents.reduce((acc, current) => !current.isHomeTeam
        ? acc += current.value
        : acc
      , 0)
    },
    allEvents(state) {
      const scorings = state.match.scoringEvents.map(e => ({ ...e, eventType: 'scoring' }));
      const playerMovements = state.match.playerMovements.map(e => ({ ...e, eventType: 'movement' }));
      return [...scorings, ...playerMovements];
    },
    matchScorings(state) {
      return state.match.scoringEvents
    },
    setScores(state) {
      return state.match.setScores
    },
    disputedNotes(state) {
      return state.match.meta ? state.match.meta.disputedNotes : [];
    },
    matchMovements(state) {
      return state.match.playerMovements
    },
    gameStatus(state) {
      return state.match.status;
    },
    matchId(state) {
      return state.match._id;
    },
    selectedTeamId(state) {
      return state.selectedTeamId;
    },
    currentTeamName(state) {
      return state.match.homeTeam && state.match.homeTeam._id === state.selectedTeamId
        ? state.match.homeTeam.name
        : state.match.awayTeam && state.match.awayTeam._id === state.selectedTeamId
          ? state.match.awayTeam.name
          : '';
    },
    hasPermission(state, getters, rootState) {
      if (rootState.user.activeRole && rootState.user.activeRole.type === roles.teamAdmin) {
        if (!rootState.user.activeRole.entity) return false;
        return getters.selectedTeamId === rootState.user.activeRole.entity._id
      }
      return true;
    },
    hasPermissionHome(state, getters, rootState) {
      if (rootState.user.activeRole && rootState.user.activeRole.type === roles.teamAdmin) {
        if (!rootState.user.activeRole.entity) return false;
        return state.match.homeTeam && state.match.homeTeam._id === rootState.user.activeRole.entity._id
      }
      return true;
    },
    hasPermissionAway(state, getters, rootState) {
      if (rootState.user.activeRole && rootState.user.activeRole.type === roles.teamAdmin) {
        if (!rootState.user.activeRole.entity) return false;
        return state.match.awayTeam && state.match.awayTeam._id === rootState.user.activeRole.entity._id
      }
      return true;
    },
    playerOfTheMatchList(state) {
      return state.playerOfTheMatchList;
    },
    competition(state) {
      return state.match.competition;
    },
    useSetScoring(state) {
      return state.match.useSetScoring;
    },
    setScoringEvent(state) {
      return state.match.setScoringEvent;
    },
  },
};
