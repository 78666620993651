import _ from 'lodash';
import { httpAuth } from '@/utils/axios';

const init = () => ({
  managerSettings: {
    matchSettings: {
      skipTimeCapture: false,
    }
  }
});

export default {
  namespaced: true,
  state: init(),
  mutations: {
    updateSettings(state, payload) {
      state.managerSettings = payload;
    },
  },
  actions: {
    async setup({ commit }) {
      const res = await httpAuth.get('assemble/api/v1/manager/users/settings/');
      const settings = _.get(res, 'data.data');
      const initSettings = _.get(init(), 'managerSettings');
      if (settings) {
        commit('updateSettings', settings);
      } else {
        commit('updateSettings', initSettings);
      }
    },
    async updateSettings({ commit }, { settings }) {
      const res = await httpAuth.put('assemble/api/v1/manager/users/settings/', {
        managerSettings: settings
      });
      const resSettings = _.get(res, 'data.data');
      if (resSettings) commit('updateSettings', resSettings);
    }
  },
  getters: {
    settings(state) {
      return _.get(state, 'managerSettings');
    },
  },
};
