<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button href="/">
          <ion-icon :icon="homeOutline"></ion-icon>
          <ion-label>Home</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="teams" :href="homeSelected.route" v-if="homeSelected.showSelected">
          <ion-icon :icon="homeSelected.icon ? homeSelected.icon : ellipseOutline"></ion-icon>
          <ion-label>{{ homeSelected.name }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="schedule" href="/tabs/matches" v-if="nationalId !== 6">
          <ion-icon :icon="listOutline"></ion-icon>
          <ion-label>Matches</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="incidents" href="/tabs/incidents">
          <ion-icon :icon="documentTextOutline"></ion-icon>
          <ion-label>Incidents</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="account" href="/tabs/account" v-if="nationalId !== 6">
          <ion-icon :icon="personOutline"></ion-icon>
          <ion-label>Profile</ion-label>
        </ion-tab-button>
        <!-- Hiding QR - PROD DEPLYOMENT 03/05/22 -->
        <!-- <ion-tab-button tab="speakers" href="/tabs/qr-scanner">
          <ion-icon :icon="scanOutline"></ion-icon>
          <ion-label>QR Scanner</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="teams" :href="getHrefTeams" v-if="nationalId !== 6">
          <ion-icon :icon="peopleOutline"></ion-icon>
          <ion-label>Teams</ion-label>
        </ion-tab-button> -->
        <!-- <ion-tab-button tab="attendees" href="/tabs/attendees">
          <ion-icon :icon="peopleOutline"></ion-icon>
          <ion-label>Attendees</ion-label>
        </ion-tab-button> -->
        <!-- <ion-tab-button tab="documents" href="/tabs/documents">
          <ion-icon :icon="documentTextOutline"></ion-icon>
          <ion-label>Documents</ion-label>
        </ion-tab-button> -->
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonPage, 
  IonIcon, 
  IonLabel, 
  IonTabBar, 
  IonTabButton, 
  IonTabs
} from '@ionic/vue';
import {
  listOutline,
  scanOutline,
  peopleOutline,
  personOutline,
  personCircleOutline,
  documentTextOutline,
  homeOutline,
  ellipseOutline
} from "ionicons/icons";
import { roles } from '../../utils/roles'; 
export default {
  components: {
    IonPage,
    IonIcon, 
    IonLabel, 
    IonTabBar, 
    IonTabButton, 
    IonTabs
  },
  data(){
    return {
      listOutline,
      scanOutline,
      peopleOutline,
      documentTextOutline,
      personCircleOutline,
      homeOutline,
      personOutline,
      ellipseOutline
    }
  },
  computed: {
    getHrefTeams(){
      const activeRole = this.$store.getters["user/activeRole"];
      if(activeRole.type === roles.teamAdmin){
        return `/teams/${activeRole.entity._id}`;
      }
      return '/tabs/teams';
    },
    nationalId() {
      return +process.env.VUE_APP_NATIONAL_ID;
    },
    homeSelected() {
      return this.$store.getters['page/tabBarHomeSelected'];
    }
  }
};
</script>

<style>
</style>