const roles = {
  assembleAdmin: 1,
  superAdmin: 2,
  stateAdmin: 3,
  associationAdmin: 4,
  clubAdmin: 5,
  teamAdmin: 6,
  referee: 7,
  matchDayAdmin: 1001,
  competitionAdmin: 1002,
};
  
const getRoleName = (type) => {
  switch (type) {
  case 1: return 'Assemble Admin';
  case 2: return 'Super Admin';
  case 3: return 'State Admin';
  case 4: return 'Association Admin';
  case 5: return 'Club Admin';
  case 6: return 'Team Admin';
  case 7: return 'Referee';
  case 1001: return 'MatchDay Admin';
  case 1002: return 'Competition Admin';
  default: return '';
  }
};
  
module.exports = {
  roles,
  getRoleName
};
  