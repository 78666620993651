const init = () => ({
  matchesRound: undefined,
  matchSegment: undefined,
  matchesCompetition: undefined,
  tabBarHomeSelected: {
    page: '',
  },
});

export default {
  namespaced: true,
  state: init(),
  mutations: {
    // eslint-disable-next-line no-unused-vars
    clear(state) {
      state = Object.assign(state, init());
    },
    setMatchesRound(state, payload) {
      state.matchesRound = payload;
    },
    setMatchesCompetition(state, payload) {
      state.matchesCompetition = payload;
    },
    setMatchSegment(state, payload) {
      state.matchSegment = payload;
    },
    setTabBarHomeSelected(state, payload) {
      state.tabBarHomeSelected = payload;
    },
  },
  actions: {
    setMatchSegment(context, segment) {
      context.commit('setMatchSegment', segment);
    },
    setMatchesRound(context, round) {
      context.commit('setMatchesRound', round);
    },
    setMatchesCompetition(context, competition) {
      context.commit('setMatchesCompetition', competition);
    },
    setTabBarHomeSelected(context, selected) {
      context.commit('setTabBarHomeSelected', selected);
    },
  },
  getters: {
    matchesRound(state) {
      return state.matchesRound;
    },
    matchesCompetition(state) {
      return state.matchesCompetition;
    },
    matchSegment(state) {
      return state.matchSegment;
    },
    tabBarHomeSelected(state) {
      return state.tabBarHomeSelected;
    },
  },
};
