import _ from 'lodash';
import moment from 'moment';
import store from '../store';
import { httpNoAuth } from '../utils/axios';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import Matches from '../views/matches/matches.vue'
import Tabs from '../views/tab-layout/index.vue'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/matches'
  // },
  // {
  //   path: '/',
  //   redirect: +process.env.VUE_APP_NATIONAL_ID === 6 ? '/tabs/qr-scanner' : '/tabs/matches',
  // },
  {
    path: '/',
    component: () => import('@/views/home/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'matches',
      },
      {
        path: 'matches',
        component: Matches,
      },
      {
        path: 'qr-scanner',
        component: () => import('@/views/qr-scanner/index.vue')
      },
      {
        path: 'attendee',
        component: () => import('@/views/qr-scanner/index.vue'),
      },
      {
        path: 'teams',
        component: () => import('@/views/teams/index.vue'),
      },
      {
        path: 'incidents',
        component: () => import('@/views/incidents/index.vue'),
      },
      {
        path: 'documents',
        component: () => import('@/views/documents/index.vue')
      },
      {
        path: 'event-attendance',
        component: () => import('@/views/event-attendance/events.vue')
      },
      {
        path: 'account',
        component: () => import('@/views/accounts/account.vue'),
      },
      {
        path: 'settings',
        component: () => import('@/views/settings/settings.vue'),
      },
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/qr-scanner/verification/:decodedText',
    name: 'qr-scanner.verfication',
    component: () => import('@/views/qr-scanner/verification.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/login',
    component: () => import('@/views/accounts/login.vue')
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/accounts/forgot-password.vue')
  },
  {
    name: 'reset.submit',
    path: '/reset/:token',
    component: () => import('@/views/accounts/reset-password.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/accounts/account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/quick-register',
    name: 'quick-register',
    component: () => import('@/views/quick-register/index.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/matches',
    name: 'matches',
    redirect: '/tabs/matches',
    // component: Matches,
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/event-attendance',
    name: 'event-attendance',
    redirect: '/tabs/event-attendance',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/matches/:id',
    name: 'matches.detail',
    component: () => import('@/views/matches/match.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/event-attendance/:id',
    name: 'event-attendance.detail',
    component: () => import('@/views/event-attendance/event.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/teams/:id',
    name: 'teams.detail',
    component: () => import('@/views/teams/team.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/matches/details2',
    name: 'matches.detail2',
    component: () => import('@/views/matches/match-alternative.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documents',
    name: 'documents',
    redirect: '/tabs/documents',
  },
  {
    path: '/incidents',
    name: 'incidents',
    redirect: '/tabs/incidents',
  },
  {
    path: '/tabs/documents/folders/:id/:name',
    name: 'folders.detail',
    component: () => import('@/views/documents/documents.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/tabs/incidents/new',
    name: 'incidents.new',
    component: () => import('@/views/incidents/new.vue'),
    meta: {
      requiresAuth: true
    },
    props: true,
  },
  {
    path: '/tabs/incidents/:id/',
    name: 'incidents.detail',
    component: () => import('@/views/incidents/_id.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/settings',
    component: () => import('@/views/settings/settings.vue')
  },
  {
    path: '/settings/competition-sort',
    component: () => import('@/views/settings/competition-sort.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => _.get(record, 'meta.requiresAuth') === true)) {
    const now = moment().valueOf();
    const expiry = store.getters['user/expiry'];
    const userId = store.getters['user/userId'];
    const isAuthenticated = store.getters['user/isAuthenticated'];
    const refreshToken = store.getters['user/refreshToken'];
    const verified = store.getters['user/isVerified'];
    const roles = store.getters['user/roles'];

    if (isAuthenticated && expiry - 300 > now) {
      // Valid Token
      if (!verified || roles.length <= 0) {
        // Account not verified, redirect to verify page
        store.commit('user/logout');
        next('/login');
      } else {
        // Account Verified
        next();
      }
    } else if (expiry - 300 <= now && refreshToken && userId) {
      // Expired Token, refresh token before going to page
      store.commit('loading', true);
      httpNoAuth.post('/assemble/api/v1/account/manager/authorize',
        {
          refresh_token: refreshToken,
          user_id: userId,
        })
        .then((response) => {
          // Refresh Successful - Login User
          store.commit('user/login', response.data);
          store.dispatch('national/setup');
          store.dispatch('user/fetchReferee');
          store.dispatch('user/fetchUserData');
          next();
        })
        .catch(() => {
          // Failed Refresh - Logout User
          store.commit('user/logout');
          next('/login');
        });
    } else {
      // No Data - Redirect to Login
      store.commit('user/logout');
      next('/login');
    }
  } else {
    next();
  }
});

export default router
