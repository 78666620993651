<template>
  <div :class="{ 'club-logo': true, 'selected-team-logo': isSelected, logoSize:(segment ==='capture' || size === 'small')? true:false }"  >
    <img width="60" :src="get(team, 'meta.imgUrl', placeholder)"  class="img-object-contain" />
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
export default {
  props: ['team', 'selected', 'segment', 'size'],
  setup(props) {
    const { selected } = toRefs(props);
    const isSelected = selected ? computed(() => selected.value === true ) : false;
    return {
      get: _.get,
      isSelected,
    }
  },
  computed: {
    placeholder() {
      return process.env.VUE_APP_PLACEHOLDER || require('@/assets/images/club-placeholder.png');
    },
  }
};
</script>

<style scoped lang="scss">
.club-logo {
  width: 4.25rem;
  height: 4.25rem;
  /* border: 2px solid rgba(226, 9, 9, 0.1); */
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 5px;
  transition: all 0.1s ease-in-out;
}
.logoSize {
  width: 2.75rem;
  height: 2.75rem;
}
.selected-team-logo {
  border: 2px solid rgba($secondary, .5);
  transform: scale(1.05);
  animation: at-ripple 0.6s linear infinite;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>