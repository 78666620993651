import _ from 'lodash';
import { httpAuth } from '@/utils/axios';

const init = () => ({
  memberTypes: [],
  scoringEvents: [],
  playerMovements: [],
  matchStatuses: [],
  seasons: [],
  currentSeason: undefined,
  timezone: 'Australia/Sydney',
});

export default {
  namespaced: true,
  state: init(),
  mutations: {
    updateMemberTypes(state, payload) {
      state.memberTypes = payload;
    },
    updateScoringEvents(state, payload) {
      state.scoringEvents = payload;
    },
    updatePlayerMovements(state, payload) {
      state.playerMovements = payload;
    },
    updateMatchStatuses(state, payload) {
      state.matchStatuses = payload;
    },
    updateSeasons(state, payload) {
      state.seasons = payload;
    },
    updateCurrentSeason(state, payload) {
      state.currentSeason = payload;
    },
    updateTimezone(state, payload) {
      state.timezone = payload;
    },
  },
  actions: {
    async setup({ commit }) {
      const res = await httpAuth.get('assemble/api/v1/manager/nationals/member-types');
      const resSeasons = await httpAuth.get('assemble/api/v1/manager/seasons');
      if(resSeasons.data.seasons){
        commit('updateSeasons', resSeasons.data.seasons);
        const currentSeason = _.find(resSeasons.data.seasons, s => s.currentSeason);
        if(currentSeason) commit('updateCurrentSeason', currentSeason);
      }
      if (res.data.data) commit('updateMemberTypes', res.data.data);
      const resEvents = await httpAuth.get('assemble/api/v1/manager/nationals/events');
      if (resEvents.data.scoringEvents) commit('updateScoringEvents', resEvents.data.scoringEvents);
      if (resEvents.data.playerMovements) commit('updatePlayerMovements', resEvents.data.playerMovements);
      if (resEvents.data.matchStatuses) commit('updateMatchStatuses', resEvents.data.matchStatuses);
      if (resEvents.data.timezone) commit('updateTimezone', resEvents.data.timezone);
    }
  },
  getters: {
    memberTypes(state) {
      return _.get(state, 'memberTypes');
    },
    playingMemberTypes(state) {
      return _.filter(_.get(state, 'memberTypes'), t=> t.playing === true);
    },
    nonplayingMemberTypes(state) {
      return _.filter(_.get(state, 'memberTypes'), t=> t.playing !== true);
    },
    scoringEvents(state) {
      return state.scoringEvents;
    },
    playerMovements(state) {
      return state.playerMovements;
    },
    matchStatuses(state) {
      return state.matchStatuses;
    },
    seasons(state) {
      return state.seasons;
    },
    currentSeason(state) {
      return state.currentSeason;
    },
    timezone(state) {
      return state.timezone;
    },

  },
};
