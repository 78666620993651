import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import PusherPlugin from './utils/pusher'

import { IonicVue } from '@ionic/vue';
import { loadingController, toastController, alertController } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/custom.scss';

import 'lodash';

import * as IonComponents from '@ionic/vue';
import store from './store'
import {httpNoAuth, httpAuth} from './utils/axios';
import moment from 'moment-timezone';


import { datadogRum } from '@datadog/browser-rum';

// import VueMask from 'v-mask'

const app = createApp(App)
  .use(store)
  .use(IonicVue)
  .use(PusherPlugin, {
    apiKey: process.env.VUE_APP_PUSHER_API_KEY,
    cluster: 'ap4',
  })
  .use(router);
  // .use(VueMask);
  
if (process.env.VUE_APP_DD_APP_ID) {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DD_APP_ID,
    clientToken: process.env.VUE_APP_DD_APP_KEY,
    site: 'datadoghq.com',
    service: 'assemble-manager',
    env: process.env.VUE_APP_DD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  
  datadogRum.startSessionReplayRecording();
}

// includes all ionic component
Object.keys(IonComponents).forEach(key => {
  if (/^Ion[A-Z]\w+$/.test(key)) {
    app.component(key, IonComponents[key]);
  }
});

app.config.globalProperties.$http = httpAuth;
app.config.globalProperties.$httpNoAuth = httpNoAuth;
app.config.globalProperties.$loadingController = loadingController;
app.config.globalProperties.$alertController = alertController;
app.config.globalProperties.moment = moment.tz.setDefault(store.getters['national/timezone']);

// app.component('google-auto-complete', () => import('./components/GoogleAutoComplete.vue'));

app.config.globalProperties.$error = (errors) => {
  toastController
    .create({
      position: 'top',
      message: _.join(_.map(errors, e=> e.message), '\n'),
      duration: 3000
    }).then(toast => {
      toast.present();
    })
};

app.config.globalProperties.$success = (message = 'Successful') => {
  toastController
    .create({
      position: 'top',
      message: message,
      duration: 3000
    }).then(toast => {
      toast.present();
    })
};

app.config.globalProperties.$confirm = async (
  message = 'Are you sure you want to continue?',
  onCancel = () => {},
  onConfirm = () => {}
) => {
  const alert = await alertController
    .create({
      header: 'Alert',
      message,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'secondary',
          handler: onCancel,
        },
        {
          text: 'Confirm',
          handler: onConfirm
        },
      ],
    });
  await alert.present();
}


app.config.errorHandler = function(message = 'An error has occured') {
  toastController
    .create({
      position: 'top',
      message,
      duration: 2000
    }).then(toast => {
      toast.present();
    })
}

router.isReady().then(() => {
  app.mount('#app');
});
