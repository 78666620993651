const init = () => ({
  incidents: []
});

export default {
  namespaced: true,
  state: init(),
  mutations: {
    clear(state) {
      state = Object.assign(state, init());
    },
    updateIncidentById(state, payload) {
      const index = state.incidents.findIndex(incident => incident._id === payload._id);
      const found = state.incidents[index];
      // update fields only, do not replace the object
      const updated = { ...found, ...payload }
      console.log({
        updated,
        index,
      })
      state.incidents.splice(index, 1, updated);
    },
    setIncidents(state, payload) {
      state.incidents = payload;
    },
    deleteIncidentById(state, payload) {
      const index = state.incidents.findIndex(incident => incident._id === payload);
      state.incidents.splice(index, 1);
    },
    addIncident(state, payload) {
      state.incidents.push(payload);
    }
  },
  actions: {
  },
  getters: {
    incidents: state => state.incidents,
  },
};
